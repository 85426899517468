import Vue from 'vue'
import VueRouter from 'vue-router'
/* eslint-disable */
Vue.use(VueRouter)

// const resultsLocalStorage = JSON.parse(localStorage.getItem('results'))
// if (resultsLocalStorage) {
//   var account_type = resultsLocalStorage.account_type
// } else {
//   var account_type = 'none'
// }

const routes = [{
    path: '/home',
    name: 'home',
    component: () =>
      import ('@/views/Home.vue'),
    // beforeEnter(to, from, next) {
    //   if (localStorage.getItem('accessToken') && account_type === 'manager') {
    //     next()
    //   } else {
    //     next('/login')
    //   }
    // },

    meta: {
      layout: 'content',
    },
  },
  {
    path: '/section',
    name: 'section',
    component: () =>
      import ('@/views/section/section.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken')) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/section/service/section_name/:section_name/section_id/:id',
    name: 'service',
    component: () =>
      import ('@/views/service/service.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken')) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/allService',
    name: 'allService',
    component: () =>
      import ('@/views/service/allService.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken')) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/customerBirthdayToday',
    name: 'customerBirthdayToday',
    component: () =>
      import ('@/views/customer/customerBirthdayToday.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken')) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/works',
    name: 'works',
    component: () =>
      import ('@/views/topWorks/topWorks.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken')) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/offers',
    name: 'offers',
    component: () =>
      import ('@/views/offers/offers.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken')) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/customer',
    name: 'customer',
    component: () =>
      import ('@/views/customer/customer.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken')) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/customerJop',
    name: 'customerJop',
    component: () =>
      import ('@/views/customerJop/customerJop.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken')) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () =>
      import ('@/views/contact/contact.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken')) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/editProfile',
    name: 'editProfile',
    component: () =>
      import ('@/views/profile/editProfile.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken')) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/notification',
    name: 'notification',
    component: () =>
      import ('@/views/notification/notification.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken')) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/',
    name: 'login',
    component: () =>
      import ('@/views/Login.vue'),

    meta: {
      layout: 'blank',
    },
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
