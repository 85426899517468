import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: null,
    firebaseToken: null,
    results: null,
    errorMessageShow: false,
    btnLoginLoading: false,

    FileUrl: 'https://api.luce-beauty-center.com/',

    // FileUrl: 'http://localhost:5030/',
  },
  mutations: {
    authUser(state, userData) {
      state.accessToken = userData.accessToken
      state.firebaseToken = userData.firebaseToken
      state.results = userData.results
      state.errorMessageShow = false
      state.btnLoginLoading = false
    },
    clearAuthUser(state) {
      state.accessToken = null
      state.results = null
      state.btnLoginLoading = false
    },
    errorLogin(state) {
      state.errorMessageShow = true
      state.btnLoginLoading = false
    },
    changeLoginBtnToTruefunction(state) {
      state.btnLoginLoading = true
    },
  },
  actions: {
    submitLogin({ commit, dispatch }, authData) {
      console.log('authData', authData)

      return Vue.axios
        .post('/login', {
          username: authData.username,
          password: authData.password,

          // auth_phone_id: authData.firebaseToken,
          // auth_firebase: authData.firebaseToken,
        })
        .then(Response => {
          console.log('response', Response)
          if (Response.data.error === false) {
            dispatch('setLogoutTimer', 3600000) // auto logout after 1 hour
            commit('authUser', {
              results: Response.data.results,
            })
            localStorage.setItem('accessToken', Response.data.results.token)
            localStorage.setItem('results', JSON.stringify(Response.data.results))
            router.push('/home').catch(err => {
              console.log(err)
            })
          } else {
            commit('errorLogin')
          }
        })
        .catch(() => {
          // console.log("error", error)
          commit('errorLogin')
        })
    },
    tryLoginAuth({ commit }) {
      const accessToken = localStorage.getItem('accessToken')
      const results = localStorage.getItem('results')
      if (!accessToken) {
        return
      }
      commit('authUser', {
        accessToken,
        results,
      })
    },
    submitLogout({ commit }) {
      commit('clearAuthUser')
      localStorage.clear('accessToken')
      localStorage.clear('results')
      router.replace('/')
    },
    setLogoutTimer({ commit }, expireIN) {
      setTimeout(() => {
        commit('clearAuthUser')
        localStorage.clear('accessToken')
        localStorage.clear('results')
        router.push('/')
      }, expireIN)
    },
    changeLoginBtnToTrue({ commit }) {
      commit('changeLoginBtnToTruefunction')
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
  },
})
