<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts
import useDynamicVh from '@core/utils/useDynamicVh'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentVerticalNavAdmin from '@/layouts/variants/content/admin-nav/adminNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

// if (account_type === 'manager') {
//   var nav = LayoutContentVerticalNav
// } else {
//   var nav = LayoutContentVerticalNavAdmin
// }

// Dynamic vh

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentVerticalNavAdmin,
    LayoutContentHorizontalNav,
    LayoutBlank,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      // const resultsLocalStorage = JSON.parse(localStorage.getItem('results'))

      // console.log('====================================')
      // console.log(appContentLayoutNav.value)
      // console.log('====================================')

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      if (route.value.meta.layout === 'content') {
        // let accountType

        // if (resultsLocalStorage) {
        //   const accountType = resultsLocalStorage
        // } else {
        //   const accountType = 'none'
        // }
        // const resultsLocalStorage = JSON.parse(localStorage.getItem('results'))

        // const accountType = resultsLocalStorage.account_type
        return 'layout-content-vertical-nav'

        // if (accountType === 'manager') {
        //   return 'layout-content-vertical-nav'
        // }
        // if (accountType === 'admin') {
        //   return 'layout-content-vertical-nav-admin'
        // }
      }

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a8bbbf;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
