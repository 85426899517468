<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <v-avatar size="40px" v-bind="attrs" color="primary" class="v-avatar-light-bg primary--text" v-on="on">
          <v-img v-if="schoolLogo" :src="$store.state.FileUrl + schoolLogo"></v-img>
          <v-img v-else :src="require('@/assets/images/avatars/1.png')"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
            <v-img v-if="schoolLogo" :src="$store.state.FileUrl + schoolLogo"></v-img>
            <v-img v-else :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align:middle">
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userData.account_name }}
            <!-- Admin -->
          </span>
          <small v-if="userData.account_type === 'manager'" class="text--disabled text-capitalize">مدير</small>
          <small v-else class="text--disabled text-capitalize">ادمن</small>
        </div>
      </div>

      <!-- <v-divider></v-divider> -->

      <!-- Profile -->
      <!-- <v-list-item href="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Email -->
      <!-- <v-list-item href="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiEmailOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Inbox</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Chat -->
      <!-- <v-list-item href="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiChatOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Chat</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-badge
            inline
            color="error"
            content="2"
          >
          </v-badge>
        </v-list-item-action>
      </v-list-item> -->

      <v-divider class="my-2"></v-divider>

      <!-- Settings -->
      <!-- <v-list-item href="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Pricing -->
      <!-- <v-list-item href="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCurrencyUsd }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Pricing</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- edit Profile -->
      <v-list-item @click="$router.push('/editProfile')">
        <v-list-item-icon class="me-2">
          <v-icon size="20">
            fa-edit
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>تعديل البروفايل</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
          <!-- <v-icon size="22">
            {{ $store.state.FileUrl + schoolLogo }}
          </v-icon> -->
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>تسجيل خروج</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'

export default {
  // setup() {
  //   const userData = JSON.parse(localStorage.getItem('results'))

  //   return {
  //     userData,
  //     icons: {
  //       mdiAccountOutline,
  //       mdiEmailOutline,
  //       mdiCheckboxMarkedOutline,
  //       mdiChatOutline,
  //       mdiCogOutline,
  //       mdiCurrencyUsd,
  //       mdiHelpCircleOutline,
  //       mdiLogoutVariant,
  //     },
  //   }
  // },
  data () {
    const userData = JSON.parse(localStorage.getItem('results'))
    const schoolLogo = userData.school_img

    return {
      userData: JSON.parse(localStorage.getItem('results')),
      schoolLogo,

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('submitLogout')
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
