import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import App from './App.vue'
// import firebaseMessaging from './firebase/firebase'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Vue.prototype.$messaging = firebaseMessaging

Vue.use(VueAxios, axios)

axios.defaults.baseURL = 'https://api.luce-beauty-center.com/api/web/'

// axios.defaults.baseURL = 'http://localhost:5030/api/web/'

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      store.dispatch('logout')
      router.push('/')
    }

    return Promise.reject(error)
  },
)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
