/* eslint-disable */
export default [{
    title: 'الصفحة الرئيسية',
    icon: 'fa-home',
    to: 'home',
  },
  {
    title: 'الاقسام',
    icon: 'fab fa-buffer',
    to: 'section',
  },
  {
    title: 'اخر الاعمال',
    icon: 'fa-spa',
    to: 'works',
  },
  {
    title: 'العروض',
    icon: 'fa-gift',
    to: 'offers',
  },
  {
    title: 'الزبائن',
    icon: 'fa-user',
    to: 'customer',
  },
  {
    title: 'المهنة',
    icon: 'fa-briefcase',
    to: 'customerJop',
  },
  {
    title: 'الاشعارات',
    icon: 'fa-bell',
    to: 'notification',
  },
  {
    title: 'اتصل بنا',
    icon: 'fa-phone-square',
    to: 'contact',
  },
]
